<script setup>
import { computed, nextTick, provide, ref, watch } from 'vue';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import { useDisplay } from 'vuetify';
import {
    mdiAccount,
    mdiAccountMultiple,
    mdiApi,
    mdiBookCogOutline,
    mdiCancel,
    mdiCloudKey,
    mdiCog,
    mdiDatabaseImport,
    mdiDolly,
    mdiHandCoin,
    mdiHomeImportOutline,
    mdiHumanDolly,
    mdiListBox,
    mdiLogout,
    mdiNoteTextOutline,
    mdiPackageVariant,
    mdiPlus,
    mdiRocketLaunch,
    mdiTableEdit,
    mdiTruckCargoContainer,
    mdiTruckCheckOutline,
    mdiTruckDeliveryOutline,
    mdiViewDashboard,
    mdiWarehouse,
} from '@mdi/js';
import LogoWhite from '../../svg/maillog_logo_nav.svg?component';
import { trans as $t } from 'laravel-vue-i18n';
import CustomerCombobox from '@/Components/CustomerCombobox.vue';
import { useForm, usePage } from '@inertiajs/vue3';

defineProps({
    modelValue: {
        type: Boolean,
        default: true,
    },
});

const open = ref(null);

const emit = defineEmits(['update:modelValue']);
const { lgAndUp } = useDisplay();

watch(lgAndUp, function (value) {
    rail.value = value;

    nextTick(() => {
        emit('update:modelValue', value);
    });
});
const closeDrawer = function () {
    if (!lgAndUp.value) emit('update:modelValue', false);
};

const rail = ref(lgAndUp.value);
const drawerOpen = computed(() => !rail.value || showCustomerMenu.value);
const toggleRail = function (value) {
    if (lgAndUp.value) {
        rail.value = value;
    }
};

const page = usePage();
// Customer Menu Stuff
const showCustomerMenu = ref(false);
const customerItems = ref([]);
const customerForm = useForm({ customers: [] });

if (page.props.auth.canPickCustomerSettings) {
    customerItems.value = page.props.auth.selectedCustomers;
    customerForm.customers = page.props.auth.selectedCustomers.map(
        (customer) => customer.id,
    );
    customerForm.defaults();
}

const submitCustomerForm = () => {
    if (customerForm.isDirty) {
        customerForm.put(route('user-profile-information.update'), {
            preserveScroll: true,
            preserveState: false,
            errorBag: 'updateProfileInformation',
        });
    }
};

provide('closeDrawer', closeDrawer);
</script>

<template>
    <v-navigation-drawer
        :rail="!drawerOpen"
        :model-value="modelValue"
        color="black"
        :expand-on-hover="lgAndUp"
        :temporary="!lgAndUp"
        @update:model-value="emit('update:modelValue', $event)"
        @update:rail="toggleRail($event)"
    >
        <v-toolbar color="black">
            <v-spacer></v-spacer>
            <v-list-item
                class="tw-px-3 tw-py-4 tw-text-center tw-leading-tight"
            >
                <LogoWhite class="tw-fill-white" width="140px"></LogoWhite>
            </v-list-item>
            <v-spacer></v-spacer>
        </v-toolbar>

        <v-divider />
        <v-list
            v-model:opened="open"
            open-strategy="single"
            nav
            class="tw-text-sm tw-font-light"
        >
            <LinkVuetify
                class="tw-text-base tw-font-light"
                :prepend-icon="mdiViewDashboard"
                as="v-list-item"
                :href="route('dashboard')"
            >
                {{ $t('Dashboard') }}
            </LinkVuetify>
            <LinkVuetify
                v-if="$page.props.auth.canViewArrivals"
                class="tw-text-base tw-font-light"
                :prepend-icon="mdiHomeImportOutline"
                as="v-list-item"
                :href="route('arrival.index')"
                >{{ $t('Warehouse Entry') }}
            </LinkVuetify>
            <LinkVuetify
                v-if="$page.props.auth.canViewStock"
                class="tw-text-base tw-font-light"
                :prepend-icon="mdiWarehouse"
                as="v-list-item"
                :href="route('stock.index')"
                >{{ $t('Stock') }}</LinkVuetify
            >

            <v-list-group
                v-if="
                    $page.props.auth.canViewAnyOrder ||
                    $page.props.auth.canViewOrders ||
                    $page.props.auth.canCreateOrders ||
                    $page.props.auth.canViewCancelledOrders
                "
                value="order"
            >
                <template #activator="{ props }">
                    <v-list-item
                        v-bind="props"
                        :prepend-icon="mdiRocketLaunch"
                        class="tw-text-base tw-font-light"
                    >
                        {{ $t('Order') }}
                    </v-list-item>
                </template>
                <LinkVuetify
                    v-if="
                        $page.props.auth.canViewAnyOrder ||
                        $page.props.auth.canViewOrders
                    "
                    :prepend-icon="mdiListBox"
                    as="v-list-item"
                    :href="route('order.index')"
                    >{{ $t('List') }}</LinkVuetify
                >
                <LinkVuetify
                    v-if="$page.props.auth.canCreateOrders"
                    as="v-list-item"
                    :prepend-icon="mdiPlus"
                    :href="route('order.create')"
                >
                    {{ $t('New') }}
                </LinkVuetify>
                <LinkVuetify
                    v-if="$page.props.auth.canCreateOrders"
                    as="v-list-item"
                    :prepend-icon="mdiDatabaseImport"
                    :href="route('order.import')"
                >
                    {{ $t('Import') }}
                </LinkVuetify>
                <LinkVuetify
                    v-if="$page.props.auth.canViewCancelledOrders"
                    :href="route('cancelled-order.index')"
                    as="v-list-item"
                    :prepend-icon="mdiCancel"
                >
                    {{ $t('Cancelled Orders') }}
                </LinkVuetify>
            </v-list-group>
            <v-list-group
                v-if="
                    $page.props.auth.canIssueGoods ||
                    $page.props.auth.canIssueGoods ||
                    $page.props.auth.canViewPackProcesses
                "
                value="fulfillment"
            >
                <template #activator="{ props }">
                    <v-list-item
                        v-bind="props"
                        class="tw-text-base tw-font-light"
                        :prepend-icon="mdiPackageVariant"
                        >Fulfillment</v-list-item
                    >
                </template>

                <LinkVuetify
                    v-if="$page.props.auth.canIssueGoods"
                    :prepend-icon="mdiTruckCheckOutline"
                    as="v-list-item"
                    :href="route('goods-arrival.index')"
                    >{{ $t('Goods Arrival') }}</LinkVuetify
                >
                <LinkVuetify
                    v-if="$page.props.auth.canViewAnyPickList"
                    as="v-list-item"
                    :href="route('pick.index')"
                    :prepend-icon="mdiHumanDolly"
                    >{{ $t('Pick') }}</LinkVuetify
                >
                <LinkVuetify
                    v-if="$page.props.auth.canViewPackProcesses"
                    as="v-list-item"
                    :href="route('pack.create')"
                    :prepend-icon="mdiPackageVariant"
                    >{{ $t('Pack') }}</LinkVuetify
                >
                <LinkVuetify
                    v-if="$page.props.auth.canIssueGoods"
                    as="v-list-item"
                    :href="route('goods-issue.show')"
                    :prepend-icon="mdiTruckDeliveryOutline"
                    >{{ $t('menu.goods-issue') }}</LinkVuetify
                >
            </v-list-group>
            <v-list-group
                v-if="
                    $page.props.auth.canViewAnyTicket ||
                    $page.props.auth.canViewAnyGoodsIssue ||
                    $page.props.auth.canCreateShippingAdvices ||
                    $page.props.auth.canAccessDevOptions
                "
                value="management"
            >
                <template #activator="{ props }">
                    <v-list-item
                        v-bind="props"
                        :prepend-icon="mdiBookCogOutline"
                        class="tw-text-base tw-font-light"
                        >{{ $t('Management') }}</v-list-item
                    >
                </template>
                <LinkVuetify
                    v-if="$page.props.auth.canViewAnyTicket"
                    as="v-list-item"
                    :href="route('ticket.index')"
                    :prepend-icon="mdiNoteTextOutline"
                    >{{ $t('Support - Tickets') }}</LinkVuetify
                >
                <LinkVuetify
                    v-if="$page.props.auth.canViewAnyGoodsIssue"
                    :href="route('goods-issue.index')"
                    :prepend-icon="mdiTruckDeliveryOutline"
                    as="v-list-item"
                    >{{ $t('Goods Issue Scans') }}</LinkVuetify
                >
                <LinkVuetify
                    v-if="$page.props.auth.canCreateShippingAdvices"
                    :prepend-icon="mdiTruckCargoContainer"
                    as="v-list-item"
                    :href="route('shipping-advice.index')"
                    >{{ $t('Shipping Advices') }}</LinkVuetify
                >
                <LinkVuetify
                    v-if="$page.props.auth.canViewShippingAgentMatrix"
                    as="v-list-item"
                    :href="route('shipping-agent-matrix.index')"
                    :prepend-icon="mdiTableEdit"
                    >{{ $t('Shipping Agent Matrix') }}</LinkVuetify
                >
                <LinkVuetify
                    v-if="$page.props.auth.canAccessDevOptions"
                    as="v-list-item"
                    :href="route('development.index')"
                    :prepend-icon="mdiCloudKey"
                    >{{ $t('Development') }}
                </LinkVuetify>
            </v-list-group>
            <v-list-group value="profile">
                <template #activator="{ props }">
                    <v-list-item
                        v-bind="props"
                        :prepend-icon="mdiCog"
                        class="tw-text-base tw-font-light"
                        >{{ $t('Settings') }}</v-list-item
                    >
                </template>
                <LinkVuetify
                    :prepend-icon="mdiAccount"
                    as="v-list-item"
                    :href="route('profile.show')"
                    >{{ $t('Profile') }}</LinkVuetify
                >
                <LinkVuetify
                    v-if="
                        $page.props.jetstream.hasApiFeatures &&
                        !$page.props.auth.isProduction
                    "
                    :prepend-icon="mdiApi"
                    as="v-list-item"
                    :href="route('api-tokens.index')"
                    >{{ $t('API Tokens') }}
                </LinkVuetify>
                <LinkVuetify
                    v-if="$page.props.auth.canViewUsers"
                    :prepend-icon="mdiAccountMultiple"
                    as="v-list-item"
                    :href="route('user.index')"
                    >{{ $t('Users') }}</LinkVuetify
                >
                <LinkVuetify
                    v-if="$page.props.auth.canViewCustomers"
                    :prepend-icon="mdiHandCoin"
                    as="v-list-item"
                    :href="route('customer.index')"
                    >{{ $t('Customers') }}</LinkVuetify
                >

                <!-- <LinkVuetify
                    v-if="$page.props.auth.canCreateWarehouseTenants"
                    as="v-list-item"
                    :href="route('warehouse.tenants.index')"
                    :prepend-icon="mdiHumanDolly"
                    >{{ $t('Warehouse Tenants') }}</LinkVuetify
                >-->
            </v-list-group>
            <LinkVuetify
                v-if="false && $page.props.auth.canViewWarehouse"
                as="v-list-item"
                :href="route('warehouse.index')"
                :prepend-icon="mdiDolly"
                class="tw-text-base tw-font-light"
                >{{ $t('Warehouse Management') }}</LinkVuetify
            >
        </v-list>
        <template #append>
            <div class="tw-p-3">
                <v-slide-x-transition hide-on-leave>
                    <CustomerCombobox
                        v-if="$page.props.auth.canPickCustomerSettings"
                        v-show="drawerOpen"
                        v-model:menu="showCustomerMenu"
                        v-model="customerForm.customers"
                        class="tw-mb-2"
                        hide-details
                        :selected="customerItems"
                        bg-color="black"
                        :label="$t('Customer')"
                        @blur="submitCustomerForm"
                        @keydown.stop
                    ></CustomerCombobox>
                </v-slide-x-transition>
                <v-fade-transition>
                    <div
                        v-if="drawerOpen"
                        class="tw-mb-2 tw-pl-1 tw-leading-loose"
                    >
                        {{
                            $page.props.auth.user.email ??
                            $page.props.auth.user.username
                        }}
                    </div>
                </v-fade-transition>
                <LinkVuetify
                    :href="route('logout')"
                    class="tw-justify-start tw-overflow-hidden tw-px-2"
                    method="post"
                    color="primary"
                    block
                >
                    <v-icon :icon="mdiLogout"></v-icon>
                    <v-slide-x-transition>
                        <span v-if="drawerOpen">{{ $t('Logout') }}</span>
                    </v-slide-x-transition>
                </LinkVuetify>
            </div>
        </template>
    </v-navigation-drawer>
</template>
