<script setup>
import DialogModal from '@/Components/DialogModal.vue';

defineProps({
    show: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits(['close']);

const quantity = defineModel({
    type: String,
    required: true,
});
</script>

<template>
    <DialogModal :show="show" @close="emit('close')">
        <template #title>
            <h3>Stück</h3>
        </template>
        <template #content>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        v-model="quantity"
                        label="Quantity"
                        type="number"
                        required
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
        <template #footer>
            <slot></slot>
        </template>
    </DialogModal>
</template>

<style scoped></style>
