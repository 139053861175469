import { ref } from 'vue';
import { watchDebounced } from '@vueuse/core';

export function useSetting(setting, initial = null, debounce = 500) {
    const settingValue = ref(initial);
    watchDebounced(
        settingValue,
        (value) => {
            axios.post(route('api.settings.store'), {
                key: setting,
                value: value,
            });
        },
        { debounce },
    );

    return settingValue;
}
