import { ref } from 'vue';

const result = ref(null);
const isFully = typeof fully !== 'undefined';

if (isFully) {
    window.onFullyScanSuccess = (code) => {
        result.value = code;
    };
    fully.bind('onQrScanSuccess', "window.onFullyScanSuccess('$code');");
}
export default function useFullyScanner() {
    if (isFully) {
        result.value = null;

        const scan = () => {
            fully.scanQrCode('Scanner', '');
        };

        return {
            scan,
            result,
            isFully,
        };
    } else {
        return {
            scan: () => {},
            result: ref(null),
            isFully,
        };
    }
}
