<script setup>
import SelectField from '@/Components/SelectField.vue';

const printer = defineModel({
    type: [String, null],
    required: true,
});
</script>

<template>
    <SelectField
        v-model="printer"
        hide-details="auto"
        :label="$t('Printer')"
        item-title="name"
        item-value="name"
        @click.stop
    >
        <template v-if="$slots.prepend" #prepend>
            <slot name="prepend"></slot>
        </template>
        <template #item="{ props: listProps, item }">
            <v-list-item
                v-bind="listProps"
                :title="item.raw.name"
                :subtitle="`${item.raw.description} (${item.raw.location})`"
            ></v-list-item>
        </template>
    </SelectField>
</template>

<style scoped></style>
