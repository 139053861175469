<script setup>
import {
    mdiArrowRight,
    mdiCheckCircle,
    mdiClose,
    mdiTouchTextOutline,
} from '@mdi/js';

const props = defineProps({
    currentBinIndex: {
        type: Number,
        required: true,
    },
    lines: {
        type: Array,
        required: true,
    },
    scansById: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['close', 'select']);

const showModal = defineModel({
    type: Boolean,
    default: false,
});

const select = (index) => {
    emit('select', index);
    showModal.value = false;
};

const isCompleted = (line) => {
    return line.quantity === props.scansById.get(line.id)?.sum('quantity') ?? 0;
};
</script>

<template>
    <v-dialog v-model="showModal" fullscreen>
        <v-card>
            <v-toolbar>
                <v-spacer></v-spacer>
                <v-btn :icon="mdiClose" @click="showModal = false"></v-btn>
            </v-toolbar>
            <v-list color="primary">
                <template v-for="(line, index) in lines" :key="index">
                    <v-list-item
                        :active="index === currentBinIndex"
                        :prepend-icon="
                            index === currentBinIndex
                                ? mdiArrowRight
                                : mdiTouchTextOutline
                        "
                        :class="{
                            'tw-bg-gray-300': index === currentBinIndex,
                        }"
                        @click="select(index)"
                    >
                        <div class="tw-flex tw-items-center tw-justify-between">
                            <span class="tw-text-2xl tw-font-bold">{{
                                line.bin_code
                            }}</span>
                            <v-icon
                                v-if="isCompleted(line)"
                                :icon="mdiCheckCircle"
                            ></v-icon>
                        </div>
                        <div class="tw-flex tw-items-center tw-justify-between">
                            <div
                                class="tw-mr-2 tw-min-w-0 tw-shrink tw-text-nowrap"
                            >
                                <div
                                    class="tw-overflow-hidden tw-overflow-ellipsis tw-text-gray-500"
                                >
                                    <span>{{ line.item.Description }}</span>
                                    <span
                                        v-if="line.item['Description 2'] !== ''"
                                        class="tw-text-gray-500"
                                        >({{
                                            line.item['Description 2']
                                        }})</span
                                    >
                                </div>
                                <div
                                    v-if="line.batch !== null"
                                    class="tw-text-gray-500"
                                >
                                    Charge: {{ line.batch }}
                                </div>
                            </div>
                            <div class="tw-text-2xl">
                                <span class="tw-font-extrabold">{{
                                    line.quantity
                                }}</span>
                                <span class="tw-ml-2 tw-text-xl">{{
                                    line.unit
                                }}</span>
                            </div>
                        </div>
                        <v-divider class="tw-mt-1"></v-divider>
                    </v-list-item>
                </template>
            </v-list>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
