<script setup>
import AutocompleteField from '@/Components/AutocompleteField.vue';
import { reactive, ref } from 'vue';

const location = defineModel({
    type: [String, null],
    required: true,
});

const loading = ref(false);
const items = ref([]);
let abortController = new AbortController();

const filters = reactive({
    code: '',
});

axios
    .get(route('api.maillog.location.index'), {
        signal: abortController.signal,
    })
    .then((response) => {
        items.value = response.data;
    });

/*watchDebounced(
    () => filters.code,
    async (value) => {
        if (value) {
            loading.value = true;
            abortController.abort();
            abortController = new AbortController();
            try {
                const response = await axios.get(
                    route('api.maillog.location.index', { filter: filters }),
                    { signal: abortController.signal },
                );
                items.value = response.data;
            } finally {
                loading.value = false;
            }
        }
    },
    { debounce: 500 },
);*/
</script>

<template>
    <AutocompleteField
        v-model="location"
        v-model:search="filters.code"
        color="primary"
        :items
        item-title="code"
        item-value="code"
        :loading="loading"
    ></AutocompleteField>
</template>

<style scoped></style>
