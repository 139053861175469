<script setup>
import { mdiPrinter } from '@mdi/js';
import PrinterSelect from '@/Components/PrinterSelect.vue';
import { usePage } from '@inertiajs/vue3';
import { ref, watch } from 'vue';
import { useSetting } from '@/lib/composables/settings.js';

defineProps({
    enabled: {
        type: Boolean,
        required: true,
    },
});
const page = usePage();
const printer = defineModel({
    type: [String, null],
    required: true,
});

const selectedPrinter = useSetting('a4_printer', printer.value, 0);
const auto = ref(true);

watch([selectedPrinter, auto], () => {
    if (auto.value) {
        printer.value = selectedPrinter.value;
    } else {
        printer.value = null;
    }
});

const loading = ref(false);
const printDeliveryNotes = async () => {
    try {
        await axios.post(
            route('api.pick.print', {
                pick_list: page.props.pick_list.id,
                printer_name: selectedPrinter.value,
            }),
        );
    } catch (e) {
        console.error(e);
    } finally {
        loading.value = false;
    }
};
</script>

<template>
    <v-col>
        <v-card>
            <v-card-text>
                <div class="tw-flex">
                    <v-checkbox
                        v-model="auto"
                        class="tw-mr-2"
                        hide-details
                        density="compact"
                    >
                        <template #label
                            ><span class="tw-text-nowrap">Auto</span></template
                        >
                    </v-checkbox>
                    <PrinterSelect
                        v-model="selectedPrinter"
                        density="compact"
                        class="tw-pointer-events-auto !tw-mb-0 tw-mr-4 tw-w-48"
                        :items="page.props.a4_printers"
                        @click.stop
                    >
                    </PrinterSelect>
                    <v-btn :disabled="!enabled" @click="printDeliveryNotes"
                        ><v-icon size="large" :icon="mdiPrinter"></v-icon
                    ></v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<style scoped></style>
