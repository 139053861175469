<script setup>
import DialogModal from '@/Components/DialogModal.vue';
import { useVModel } from '@vueuse/core';
import { mdiCancel, mdiClose } from '@mdi/js';
import OrderSummarySection from '@/Pages/Order/Partials/OrderSummarySection.vue';
import ConfirmationModal from '@/Components/ConfirmationModal.vue';
import { useForm } from '@inertiajs/vue3';
import { nextTick, ref } from 'vue';

const props = defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
    order: {
        type: Object,
        default: null,
    },
});
const emit = defineEmits([
    'update:modelValue',
    'close',
    'cancelled',
    'action:complete',
]);
const show = useVModel(props, 'modelValue', emit);
const close = () => {
    show.value = false;
    emit('close');
};

const confirmingCancelOrder = ref(false);
const cancelOrderForm = useForm({});
const cancelOrder = () => {
    cancelOrderForm.post(
        route('order.cancel', { order_number: props.order.order_number }),
        {
            onFinish() {
                confirmingCancelOrder.value = false;
                emit('cancelled', props.order.order_number);
                close();
            },
        },
    );
};

const showMenu = ref(false);
const orderCompleting = ref(false);
const completeOrder = async () => {
    orderCompleting.value = true;
    try {
        await axios.post(
            route('api.maillog.order.complete', props.order.order_number),
        );

        emit('action:complete');
        showMenu.value = false;
        await nextTick();
        close();
    } catch (e) {
        console.error(e);
    }
    orderCompleting.value = false;
};
</script>

<template>
    <DialogModal v-model="show" class="tw-max-w-6xl" @close="close">
        <template #title>
            <div class="tw-mt-2 tw-flex tw-justify-between">
                <h6 class="tw-font-sans tw-text-lg">
                    {{ $t('Order Details') }}
                </h6>
                <v-icon :icon="mdiClose" @click="close"></v-icon>
            </div>
            <div
                v-if="order !== null && !Number.isInteger(order.order_number)"
                class="tw-mt-2 tw-flex"
            >
                <v-btn
                    v-if="
                        order.fulfillment_status_sequence !== null &&
                        order.fulfillment_status_sequence < 60
                    "
                    :disabled="order.is_cancelled"
                    :color="order.is_cancelled ? 'surface' : 'danger'"
                    size="small"
                    :prepend-icon="mdiCancel"
                    @click="confirmingCancelOrder = true"
                >
                    {{
                        !order.is_cancelled
                            ? $t('Cancel Order')
                            : $t('Cancelled')
                    }}
                </v-btn>
                <v-spacer></v-spacer>

                <v-menu
                    v-if="order.can_access_order_actions"
                    v-model="showMenu"
                    :close-on-content-click="false"
                >
                    <template #activator="{ props: params }">
                        <v-btn
                            v-bind="params"
                            variant="outlined"
                            color="secondary"
                            size="small"
                            >{{ $t('Order Actions') }}</v-btn
                        >
                    </template>
                    <v-list density="compact" width="250">
                        <v-list-item
                            v-if="order.can_access_order_actions"
                            :disabled="order.fulfillment_status_code === 'MMF'"
                            @click="completeOrder"
                        >
                            <div class="tw-flex tw-w-full tw-justify-between">
                                <span><b>MMF</b> Status Setzen</span>
                                <v-progress-circular
                                    v-if="orderCompleting"
                                    size="small"
                                    indeterminate
                                ></v-progress-circular>
                            </div>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </template>
        <template v-if="order !== null" #content>
            <ConfirmationModal
                :show="confirmingCancelOrder"
                :toolbar-props="{
                    color: order.status === 0 ? 'secondary' : 'danger',
                }"
            >
                <template #title>
                    {{ $t('order.cancel.title') }}
                </template>
                <template #content>
                    <p>
                        {{
                            $t('order.cancel.content', {
                                order_number: order.order_number,
                            })
                        }}
                    </p>
                    <template v-if="order.status === 1">
                        <p class="tw-mt-4">
                            {{ $t('order.cancel.content2') }}
                        </p>
                        <p>{{ $t('order.cancel.content3') }}</p>
                    </template>
                </template>
                <template #footer>
                    <v-btn
                        :loading="cancelOrderForm.processing"
                        class="tw-mr-2"
                        :color="order.status === 0 ? 'primary' : 'danger'"
                        variant="elevated"
                        @click="cancelOrder"
                        >{{ $t('Cancel Order') }}
                    </v-btn>
                    <v-btn color="danger" @click="confirmingCancelOrder = false"
                        >{{ $t('Cancel') }}
                    </v-btn>
                </template>
            </ConfirmationModal>
            <OrderSummarySection
                class="tw-mb-4"
                :order="order"
            ></OrderSummarySection>
        </template>
        <template v-else #content>
            <div class="tw-w-full tw-py-24 tw-text-center">
                <v-progress-circular
                    indeterminate
                    :size="61"
                    :width="7"
                ></v-progress-circular>
            </div>
        </template>
    </DialogModal>
</template>

<style scoped></style>
