<script setup>
import TicketContentCardField from '@/Pages/Ticket/Partials/TicketContentCardField.vue';
import AttachmentsCardField from '@/Pages/Ticket/Partials/AttachmentsCardField.vue';
import SaveButton from '@/Components/SaveButton.vue';
import { computed, inject, watch } from 'vue';
import { useForm } from '@inertiajs/vue3';
import {
    mdiArrowDownCircle,
    mdiArrowUpCircle,
    mdiFileEdit,
    mdiTrashCan,
} from '@mdi/js';

const props = defineProps({
    ticket: {
        type: Object,
        required: true,
    },
    content: {
        type: Object,
        required: true,
    },
    edit: {
        type: Boolean,
        required: true,
    },
});
const emit = defineEmits(['unfold', 'fold']);
const confirm = inject('confirm');

const updateContentForm = useForm({
    item_number_1: props.content.item_number_1,
    item_number_2: props.content.item_number_2,
    batch: props.content.batch,
    quantity: props.content.quantity,
    external_shipment_id: props.content.external_shipment_id,
    location_code: props.content.location_code,
    bin_code: props.content.bin_code,
    note: props.content.note,
});

watch(
    () => props.content,
    () => {
        updateContentForm.reset({
            item_number_1: props.content.item_number_1,
            item_number_2: props.content.item_number_2,
            quantity: props.content.quantity,
            external_shipment_id: props.content.external_shipment_id,
            note: props.content.note,
        });
    },
);

const formatDate = (date) => {
    return moment(date).format('DD.MM.YYYY HH:mm');
};

const submitUpdateContentForm = async () => {
    updateContentForm.put(
        route('ticket.content.update', {
            ticket: props.ticket.id,
            content: props.content.id,
        }),
        {
            preserveScroll: true,
            errorBag: 'updateContentForm.' + props.content.id,
        },
    );
};

const deleteContentForm = useForm({});
const submitDeleteContentForm = async () => {
    if (
        await confirm.value(
            'Sind Sie sicher, dass Sie den Eintrag löschen wollen?',
        )
    ) {
        deleteContentForm.delete(
            route('ticket.content.destroy', {
                ticket: props.ticket.id,
                content: props.content.id,
            }),
            {
                preserveScroll: true,
            },
        );
    }
};

const dotColor = computed(() =>
    props.content.subtype_id !== null
        ? props.edit
            ? 'surface'
            : 'primary'
        : 'secondary',
);
const size = computed(() =>
    props.content.subtype_id === null ? 'x-small' : 'default',
);

const unfold = () => {
    emit('unfold', props.content.foldedIndex);
};
const fold = () => {
    emit('fold', props.content.foldedIndex);
};
</script>

<template>
    <v-timeline-item :dot-color width="90%" :size>
        <template
            v-if="content.folded === true || content.folded === false"
            #opposite
        >
            <div class="tw-whitespace-nowrap">
                <span v-if="content.content"
                    >{{ content.content.length }}
                    <span class="tw-mr-4">Events</span></span
                >
                <span
                    v-if="content.folded"
                    class="tw-cursor-pointer"
                    @click="unfold"
                    ><v-icon color="primary" :icon="mdiArrowDownCircle"></v-icon
                ></span>
                <span
                    v-else-if="content.folded === false"
                    class="tw-cursor-pointer"
                    @click="fold"
                    ><v-icon color="primary" :icon="mdiArrowUpCircle"></v-icon
                ></span>
            </div>
        </template>

        <template v-if="props.content.subtype_id !== null && edit" #icon>
            <v-speed-dial location="left center">
                <template #activator="{ props: activatorProps }">
                    <v-btn
                        v-bind="activatorProps"
                        :icon="mdiFileEdit"
                        size="x-small"
                        class="tw-h-full tw-min-h-0 tw-w-full tw-min-w-0 tw-p-0"
                        color="primary"
                    ></v-btn>
                </template>
                <v-btn
                    key="1"
                    size="x-small"
                    color="danger"
                    :icon="mdiTrashCan"
                    @click="submitDeleteContentForm"
                ></v-btn>
            </v-speed-dial>
        </template>

        <div class="tw-flex tw-w-full tw-grow tw-items-center">
            <div class="tw-mr-8 tw-whitespace-nowrap">
                <span class="tw-block">{{
                    formatDate(content.created_at)
                }}</span>
                <span class="tw-block tw-text-xs">
                    {{ content.user.username }}
                </span>
            </div>
            <v-form
                v-if="content.subtype_id !== null"
                class="tw-flex tw-grow"
                @submit.prevent="submitUpdateContentForm"
            >
                <v-card class="tw-grow">
                    <v-card-text>
                        <v-row>
                            <TicketContentCardField
                                v-model="updateContentForm.location_code"
                                cols="6"
                                :content
                                field="location_code"
                                type="location_code"
                                :edit
                                :error-messages="
                                    updateContentForm.errors.location_code
                                "
                            ></TicketContentCardField>
                            <TicketContentCardField
                                v-model="updateContentForm.bin_code"
                                cols="6"
                                :content
                                field="bin_code"
                                type="bin_code"
                                :location-code="updateContentForm.location_code"
                                :edit
                                :error-messages="
                                    updateContentForm.errors.bin_code
                                "
                            ></TicketContentCardField>
                            <TicketContentCardField
                                v-model="updateContentForm.item_number_1"
                                :edit
                                :content
                                field="item_number_1"
                                type="item"
                                :error-messages="
                                    updateContentForm.errors.item_number_1
                                "
                            ></TicketContentCardField>
                            <TicketContentCardField
                                v-model="updateContentForm.batch"
                                :edit
                                :content
                                field="batch"
                                type="batch"
                                :item-number="updateContentForm.item_number_1"
                                :error-messages="updateContentForm.errors.batch"
                            ></TicketContentCardField>
                            <TicketContentCardField
                                v-model="updateContentForm.item_number_2"
                                :edit
                                :content
                                field="item_number_2"
                                type="item"
                                :error-messages="
                                    updateContentForm.errors.item_number_2
                                "
                            ></TicketContentCardField>
                            <TicketContentCardField
                                v-model="updateContentForm.quantity"
                                :edit
                                :content
                                field="quantity"
                                type="number"
                                :error-messages="
                                    updateContentForm.errors.quantity
                                "
                            ></TicketContentCardField>
                            <TicketContentCardField
                                v-model="updateContentForm.external_shipment_id"
                                :edit
                                :content
                                field="external_shipment_id"
                                :error-messages="
                                    updateContentForm.errors
                                        .external_shipment_id
                                "
                            ></TicketContentCardField>
                        </v-row>
                        <v-row>
                            <TicketContentCardField
                                v-model="updateContentForm.note"
                                :edit
                                :content
                                field="note"
                                type="textarea"
                                input-class="tw-resize-y"
                                :error-messages="updateContentForm.errors.note"
                            >
                            </TicketContentCardField>
                        </v-row>
                        <v-row>
                            <AttachmentsCardField
                                :content
                                :error-messages="
                                    updateContentForm.errors.attachments
                                "
                            ></AttachmentsCardField>
                        </v-row>
                    </v-card-text>
                    <v-card-actions v-if="updateContentForm.isDirty">
                        <v-spacer></v-spacer>
                        <v-btn
                            v-if="updateContentForm.isDirty"
                            :disabled="updateContentForm.processing"
                            color="black"
                            size="small"
                            @click="updateContentForm.reset()"
                        >
                            {{ $t('Reset') }}
                        </v-btn>
                        <SaveButton
                            v-if="updateContentForm.isDirty"
                            :loading="updateContentForm.processing"
                            type="submit"
                            size="small"
                        ></SaveButton>
                    </v-card-actions>
                </v-card>
            </v-form>
            <!-- subtype_id not null-->
            <v-card v-if="content.note === 'updated'" class="tw-grow">
                <v-card-text>
                    <span class="tw-font-black"
                        >{{ content.user.first_name }}
                        {{ content.user.last_name }}</span
                    >
                    hat das Ticket
                    <span class="tw-font-black">#{{ ticket.id }}</span>
                    aktualisiert.
                </v-card-text>
            </v-card>
        </div>
    </v-timeline-item>
</template>

<style scoped></style>
