<script setup>
import { Head, usePage } from '@inertiajs/vue3';
import { computed, inject, ref, watch } from 'vue';
import BinCard from '@/Pages/Pick/Partials/BinCard.vue';
import {
    mdiArrowLeft,
    mdiArrowRight,
    mdiBarcodeScan,
    mdiCheckOutline,
    mdiInformation,
    mdiPlus,
} from '@mdi/js';
import PickListModal from '@/Pages/Pick/Partials/PickListModal.vue';
import usePickScans from '@/lib/composables/pick-scans.js';
import { collect } from 'collect.js';
import AppBarActionsTeleport from '@/Components/AppBarActionsTeleport.vue';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import PackageExpansionPanel from '@/Pages/Pick/Partials/PackageExpansionPanel.vue';
import useBarcodeScanner from '@/lib/composables/barcode-scanner.js';
import QuantityModal from '@/Pages/Pick/Partials/QuantityModal.vue';
import { trans as $t } from 'laravel-vue-i18n';
import { useDisplay } from 'vuetify';
import PrintOptions from '@/Pages/Pick/Partials/PrintOptions.vue';
import useFullyScanner from '@/lib/composables/fully-scanner.js';

const flash = inject('flash');

const page = usePage();
const { smAndDown: mobile } = useDisplay();
const currentPackageId = ref(page.props.pick_list.current_pick_package_id);
const currentPackageIdentifier = ref(
    page.props.pick_list.current_package.identifier,
);

const errorMessage = ref(null);
const printer = ref(page.props.selected_printer);

const {
    scan,
    scans,
    loading,
    transactionsPending,
    pauseTransactions,
    resumeTransactions,
    submitTransactions,
    errorMessage: printingErrorMessage,
} = usePickScans(
    page.props.pick_list.id,
    currentPackageId,
    page.props.pick_list.scans,
    printer,
);

const pauseKeydownEventListener = inject('pauseCaptureKeydown');

const { result: fullyResult, scan: fullyScan, isFully } = useFullyScanner();
const { onBarcodeScanned } = useBarcodeScanner({
    pauseEventListener: pauseKeydownEventListener,
});
const scanned = ref(false);
const onScanned = (barcode) => {
    scanned.value = barcode === currentBin.value.ean_code;

    if (scanned.value === false) {
        errorMessage.value = 'Falscher Lagerplatz gescannt.';
    }
};
onBarcodeScanned((barcode, e) => {
    e.preventDefault();
    e.stopPropagation();
    onScanned(barcode);
});
watch(fullyResult, (value) => {
    if (value) {
        onScanned(value);
    }
});

const binsById = computed(() => {
    return page.props.pick_list.lines.reduce((acc, bin) => {
        acc[bin.id] = bin;
        return acc;
    }, {});
});
const scansById = computed(() => {
    return collect(scans.value).groupBy('pick_list_line_id');
});
const totalQuantity = page.props.pick_list.lines.reduce(
    (acc, line) => acc + line.quantity,
    0,
);
const scannedQuantity = computed(() =>
    scans.value.reduce((acc, scan) => acc + scan.quantity, 0),
);
const rest = computed(() => totalQuantity - scannedQuantity.value);

const currentBinIndex = ref(null);
for (let i = 0; i < page.props.pick_list.lines.length; i++) {
    const line = page.props.pick_list.lines[i];
    if (line.quantity !== scansById.value.get(line.id)?.sum('quantity') ?? 0) {
        currentBinIndex.value = i;
        break;
    }

    if (i === page.props.pick_list.lines.length - 1) {
        currentBinIndex.value = i;
    }
}

const currentBin = computed(() => {
    return page.props.pick_list.lines[currentBinIndex.value];
});

const previousBin = computed(() => {
    if (currentBinIndex.value === 0) {
        return null;
    }
    return page.props.pick_list.lines[currentBinIndex.value - 1];
});
const nextBin = computed(() => {
    if (currentBinIndex.value === page.props.pick_list.lines.length - 1) {
        return null;
    }
    return page.props.pick_list.lines[currentBinIndex.value + 1];
});

watch(currentBinIndex, () => {
    scanned.value = false;
    errorMessage.value = null;
});
const next = () => {
    if (currentBinIndex.value < page.props.pick_list.lines.length - 1) {
        currentBinIndex.value++;
    }
};
const previous = () => {
    if (currentBinIndex.value > 0) {
        currentBinIndex.value--;
    }
};
const showPickListModal = ref(false);

const confirm = (bin) => {
    scan(bin.id, remainingQuantity.value);
    if (currentBinIndex.value < page.props.pick_list.lines.length - 1) {
        currentBinIndex.value++;
    }
};

const partialConfirm = (bin) => {
    scan(bin.id, Number(quantity.value));
    if (bin.quantity === scansById.value.get(bin.id)?.sum('quantity')) {
        if (currentBinIndex.value < page.props.pick_list.lines.length - 1) {
            currentBinIndex.value++;
        }
    }
    showQuantityModal.value = false;
};

const quantity = ref('0');
const remainingQuantity = computed(() => {
    return (
        currentBin.value.quantity -
        (scansById.value.get(currentBin.value.id)?.sum('quantity') ?? 0)
    );
});

watch(rest, async (value, oldValue) => {
    if (value === 0) {
        pauseTransactions();
        await submitTransactions();
    } else if (oldValue === 0) {
        resumeTransactions();
    }
});

const showQuantityModal = ref(false);
const promptAmount = (bin) => {
    showQuantityModal.value = true;
    quantity.value = (
        bin.quantity - (scansById.value.get(bin.id)?.sum('quantity') ?? 0)
    ).toString();
};

const scansByPackageId = computed(() => {
    return collect(scans.value).groupBy('pick_package_id');
});

const creatingPackage = ref(false);
const savedPackages = ref([]);
const nextPackage = async () => {
    creatingPackage.value = true;
    try {
        const response = await axios.post(
            route('api.pick.packages.create', {
                pick_list: page.props.pick_list.id,
            }),
        );

        currentPackageId.value = response.data.data.id;
        currentPackageIdentifier.value = response.data.data.identifier;
        savedPackages.value.push(response.data.data);
    } catch (e) {
        console.error(e);
    }
    creatingPackage.value = false;
};

const packages = computed(() => [
    ...page.props.pick_list.packages,
    ...savedPackages.value,
]);

const packagesById = computed(() => {
    return collect(packages.value).keyBy('id');
});

watch(printingErrorMessage, (value) => {
    if (value) {
        flash(value, 'danger');
    }
});
</script>

<template>
    <Head title="Pick"></Head>
    <v-container :class="{ 'tw-p-[16px]': mobile }">
        <AppBarActionsTeleport>
            <v-btn
                :loading="creatingPackage"
                :disabled="
                    (scansByPackageId.get(currentPackageId)?.sum('quantity') ??
                        0) === 0 ||
                    transactionsPending ||
                    rest === 0
                "
                class="tw-mr-2"
                color="secondary"
                :prepend-icon="mdiPlus"
                @click="nextPackage"
                >PAL</v-btn
            >
            <LinkVuetify
                method="delete"
                :show-loading="true"
                :href="
                    route('pick.reset', { pick_list: page.props.pick_list.id })
                "
                color="danger"
                >Reset</LinkVuetify
            >
        </AppBarActionsTeleport>
        <QuantityModal
            v-model="quantity"
            :show="showQuantityModal"
            @close="showQuantityModal = false"
        >
            <v-btn color="danger" @click="showQuantityModal = false">{{
                $t('Cancel')
            }}</v-btn>
            <v-btn
                :disabled="Number(quantity) > remainingQuantity"
                color="primary"
                @click="partialConfirm(currentBin)"
                >Fertig</v-btn
            >
        </QuantityModal>
        <PickListModal
            v-model="showPickListModal"
            :current-bin-index
            :lines="page.props.pick_list.lines"
            :scans-by-id
            @select="currentBinIndex = $event"
        ></PickListModal>
        <v-row>
            <PackageExpansionPanel
                v-if="packages.length > 1"
                :current-package-identifier
                :packages
                :bins-by-id
                :scans-by-package-id
            ></PackageExpansionPanel>
        </v-row>
        <v-row>
            <v-col :class="{ 'tw-p-0': mobile }">
                <BinCard
                    :packages-by-id
                    :rest
                    :loading="loading"
                    :bin="currentBin"
                    :previous-bin
                    :next-bin
                    :barcode-scanned="scanned"
                    :current-package-identifier
                    :scans="scansById.get(currentBin.id)"
                    :error-message
                    :stock="page.props.bins"
                    @confirm="confirm"
                    @partial-confirm="promptAmount"
                >
                    <template #buttons>
                        <v-btn
                            v-if="isFully"
                            class="tw-mr-2"
                            @click="fullyScan"
                        >
                            <v-icon
                                size="large"
                                :icon="mdiBarcodeScan"
                            ></v-icon>
                        </v-btn>
                    </template>

                    <v-btn
                        :disabled="previousBin === null"
                        :prepend-icon="mobile ? null : mdiArrowLeft"
                        color="secondary"
                        variant="elevated"
                        @click="previous"
                        ><span v-if="!mobile">Zurück</span>
                        <span v-else
                            ><v-icon :icon="mdiArrowLeft"></v-icon
                        ></span>
                    </v-btn>

                    <div
                        class="tw-ml-2 tw-flex tw-items-center tw-text-2xl tw-font-bold"
                    >
                        {{ currentBinIndex + 1 }} /
                        {{ page.props.pick_list.lines.length }}
                    </div>

                    <v-spacer></v-spacer>
                    <span
                        v-if="rest === 0"
                        class="tw-rounded-xl tw-border-4 tw-border-solid tw-border-white tw-bg-red-600 tw-p-4 tw-text-lg tw-font-bold tw-uppercase tw-text-white"
                        ><template v-if="!mobile">{{
                            $t('Completed')
                        }}</template>
                        <template v-else
                            ><v-icon
                                size="large"
                                :icon="mdiCheckOutline"
                            ></v-icon
                        ></template>
                    </span>
                    <v-btn color="info" @click="showPickListModal = true">
                        <v-icon :icon="mdiInformation" size="32"></v-icon>
                        <span v-if="!mobile" class="tw-text-lg">Liste</span>
                    </v-btn>
                    <v-btn
                        :disabled="nextBin === null"
                        :append-icon="mobile ? null : mdiArrowRight"
                        color="secondary"
                        variant="elevated"
                        @click="next"
                    >
                        <span v-if="!mobile">Weiter</span>
                        <span v-else
                            ><v-icon :icon="mdiArrowRight"></v-icon
                        ></span>
                    </v-btn>
                </BinCard>
            </v-col>
        </v-row>
        <v-row>
            <PrintOptions
                v-model="printer"
                :enabled="rest === 0"
            ></PrintOptions>
        </v-row>
    </v-container>
</template>

<style scoped></style>
