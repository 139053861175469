<script setup>
import FormSection from '@/Components/FormSection.vue';
import { useVModel } from '@vueuse/core';
import { useForm } from '@inertiajs/vue3';
import { collect } from 'collect.js';
import { computed } from 'vue';
import CheckboxField from '@/Components/CheckboxField.vue';
import PrinterSelect from '@/Components/PrinterSelect.vue';

const props = defineProps({
    modelValue: {
        type: Object,
        default: null,
    },
    user: {
        type: Object,
        default: null,
    },
    canUpdateRoles: {
        type: Boolean,
        default: false,
    },
    settings: {
        type: Array,
        default: () => [],
    },
    submit: {
        type: Boolean,
        default: true,
    },
});
const emit = defineEmits(['update:modelValue', 'submitted']);

let form = useVModel(props, 'modelValue', emit);

if (form.value === null) {
    const formData = {};

    if (props.canUpdateRoles) {
        formData.permissions = props.user.permissions;
    }

    if (props.settings && props.settings.length > 0) {
        if (props.user?.settable_settings) {
            formData.settings = props.user.settable_settings;
        } else {
            formData.settings = collect(props.settings)
                .mapWithKeys((setting) => [setting.value, setting.default])
                .all();
        }
    }

    form = useForm(formData);
}

const submitForm = () => {
    if (props.submit) {
        (form.value ?? form).put(
            route('user.permissions.update', { user: props.user.id }),
            { errorBag: 'updatePermissions', preserveScroll: true },
        );
    }
};

const checkboxSettings = computed(() => {
    return props.settings.filter((setting) => setting.type === 'checkbox');
});

const labelPrinterSetting = computed(() => {
    return props.settings.find((setting) => setting.value === 'label_printer');
});
const a4PrinterSetting = computed(() => {
    return props.settings.find((setting) => setting.value === 'a4_printer');
});
</script>

<template>
    <FormSection class="tw-mt-4" @submitted="submitForm">
        <template #title>{{ $t('Permissions') }}</template>
        <template #description>{{
            $t(
                'Select the permissions that the user should have. These Permissions are independent from the role you have chosen for the user.',
            )
        }}</template>
        <template #form>
            <CheckboxField
                v-if="props.canUpdateRoles"
                v-model="form.permissions"
                :multiple="true"
                hide-details
                value="order more than stock"
                :label="$t('Order more than on stock')"
            ></CheckboxField>
            <CheckboxField
                v-for="setting in checkboxSettings"
                :key="setting['value']"
                v-model="form.settings[setting['value']]"
                :label="setting['name']"
            />
            <h4 class="tw-mb-1 tw-font-normal tw-uppercase">Standarddrucker</h4>
            <PrinterSelect
                v-if="labelPrinterSetting"
                v-model="form.settings[labelPrinterSetting.value]"
                :items="labelPrinterSetting.options"
                :label="labelPrinterSetting.name"
            >
            </PrinterSelect>
            <PrinterSelect
                v-if="a4PrinterSetting"
                v-model="form.settings[a4PrinterSetting.value]"
                :items="a4PrinterSetting.options"
                :label="a4PrinterSetting.name"
            ></PrinterSelect>
        </template>
        <template v-if="submit" #actions>
            <v-btn
                :disabled="!form.isDirty"
                :loading="form.processing"
                variant="elevated"
                color="primary"
                type="submit"
            >
                {{ $t('Save') }}
            </v-btn>
        </template>
    </FormSection>
</template>

<style scoped></style>
