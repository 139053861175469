<script setup>
import { usePage } from '@inertiajs/vue3';
import PickIndexTableRow from '@/Pages/Pick/Partials/PickIndexTableRow.vue';
import { onBeforeMount, ref } from 'vue';
import { useLocalStorage, watchIgnorable } from '@vueuse/core';
import SelectField from '@/Components/SelectField.vue';
import CheckboxField from '@/Components/CheckboxField.vue';
import { useDisplay } from 'vuetify';
import CustomerSelect from '@/Components/CustomerSelect.vue';

const page = usePage();
const { smAndDown: mobile } = useDisplay();

const pickLists = ref([]);

const filter = useLocalStorage(
    'pick_filter',
    {
        location: 'POT',
        open: true,
        customer_number: null,
    },
    {
        mergeDefaults: true,
    },
);
const currentPage = ref(1);
const totalPages = ref(null);
const loading = ref(true);

onBeforeMount(async () => {
    const response = await axios.get(route('api.pick.index'), {
        params: {
            filter: filter.value,
            page: currentPage.value,
        },
    });
    loading.value = false;
    pickLists.value = response.data.data;
    totalPages.value = response.data.meta.last_page;
});

const { ignoreUpdates } = watchIgnorable(
    [
        () => filter.value.open,
        () => filter.value.location,
        currentPage,
        () => filter.value.customer_number,
    ],
    async (value, oldValue) => {
        ignoreUpdates(() => {
            if (value[1] !== oldValue[1] || value[3] !== oldValue[3]) {
                currentPage.value = 1;
            }
        });
        loading.value = true;
        const response = await axios.get(route('api.pick.index'), {
            params: {
                filter: filter.value,
                page: currentPage.value,
            },
        });
        pickLists.value = response.data.data;
        totalPages.value = response.data.meta.last_page;
        loading.value = false;
    },
    {
        deep: true,
    },
);
</script>

<template>
    <v-container fluid :class="{ 'tw-p-0': mobile }">
        <v-toolbar>
            <SelectField
                v-model="filter.location"
                clearable
                hide-details
                :items="page.props.locations"
                label="Standort"
            ></SelectField>
            <CustomerSelect
                v-model="filter.customer_number"
                class="tw-mb-2"
                hide-details
                label="Kunde"
            ></CustomerSelect>
            <CheckboxField v-model="filter.open" label="Offen" />
        </v-toolbar>
        <v-card>
            <div>
                <div v-if="!loading">
                    <PickIndexTableRow
                        v-for="pickList in pickLists"
                        :key="pickList.ean"
                        :pick-list="pickList"
                    ></PickIndexTableRow>
                </div>
                <div
                    v-else
                    class="tw-flex tw-h-44 tw-w-full tw-items-center tw-justify-center"
                >
                    <v-progress-circular
                        color="secondary"
                        indeterminate
                    ></v-progress-circular>
                </div>
                <v-pagination
                    v-model="currentPage"
                    :length="totalPages"
                    active-color="primary"
                ></v-pagination>
            </div>
        </v-card>
    </v-container>
</template>

<style scoped></style>
