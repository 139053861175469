<script setup>
import AutocompleteField from '@/Components/AutocompleteField.vue';
import { reactive, ref, watch } from 'vue';
import { watchDebounced } from '@vueuse/core';

const props = defineProps({
    locationCode: {
        type: [String, null],
        default: null,
    },
    resetOnLocationChange: {
        type: Boolean,
        default: true,
    },
});

const binCode = defineModel({
    type: [String, null],
    required: true,
});

const loading = ref(false);
const items = ref([]);

const filters = reactive({
    code: '',
    location_code: props.locationCode,
});

let abortController = null;

watch(
    () => props.locationCode,
    (value) => {
        filters.location_code = value;
        items.value = [];

        if (props.resetOnLocationChange) {
            binCode.value = null;
        }
    },
);

watchDebounced(
    filters,
    async (value) => {
        if (value.code && value.code !== binCode.value) {
            loading.value = true;

            if (abortController) {
                abortController.abort();
            }
            abortController = new AbortController();

            try {
                const response = await axios.get(
                    route('api.maillog.bin.index', { filter: filters }),
                    { signal: abortController.signal },
                );
                items.value = response.data;
            } finally {
                loading.value = false;
            }
        }
    },
    { debounce: 500 },
);
</script>

<template>
    <AutocompleteField
        v-model="binCode"
        v-model:search="filters.code"
        color="primary"
        :items
        item-title="code"
        item-value="code"
        :loading="loading"
    ></AutocompleteField>
</template>

<style scoped></style>
