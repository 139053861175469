<script setup>
import DialogModal from '@/Components/DialogModal.vue';
import SubtypeCard from '@/Pages/Ticket/Partials/SubtypeCard.vue';
import { useForm } from '@inertiajs/vue3';
import SelectField from '@/Components/SelectField.vue';
import { computed, watch } from 'vue';
import SaveButton from '@/Components/SaveButton.vue';

const props = defineProps({
    ticket: {
        type: Object,
        required: true,
    },
});

const show = defineModel({
    type: Boolean,
    required: true,
});
const subtypes = computed(() =>
    props.ticket.type.subtypes.filter(
        (subtype) => subtype.name !== 'ticket-meta',
    ),
);

const subtype = computed(() =>
    subtypes.value.find((subtype) => subtype.id === form.subtype_id),
);

const form = useForm({
    subtype_id: subtypes.value[0]?.id,
    note: null,
    item_number_1: null,
    item_number_2: null,
    external_shipment_id: null,
    quantity: null,
    location_code: null,
    bin_code: null,
    attachments: [],
});

watch(
    () => form.subtype_id,
    () => {
        form.defaults({
            subtype_id: form.subtype_id,
        });
        form.reset();
        form.clearErrors();
    },
);

const submitCreateTicketContentForm = () => {
    form.post(
        route('ticket.content.store', {
            ticket: props.ticket.id,
        }),
        {
            preserveScroll: true,
            errorBag: 'createTicketContentForm',
            onSuccess: close,
        },
    );
};

const close = () => {
    show.value = false;
    form.reset();
};
</script>

<template>
    <DialogModal :show @close="show = false">
        <template #title
            ><span class="tw-text-xl tw-font-light tw-uppercase">{{
                $t('Entry')
            }}</span></template
        >
        <template #content>
            <SelectField
                v-model="form.subtype_id"
                :items="subtypes"
                item-title="name"
                item-value="id"
                class="tw-mt-4"
                :error-messages="form.errors.subtype_id"
            ></SelectField>
            <SubtypeCard
                v-model="form"
                :create-ticket-form="form"
                :subtype
            ></SubtypeCard>
        </template>
        <template #footer>
            <v-spacer />
            <v-btn color="danger" variant="elevated" @click="close">{{
                $t('Cancel')
            }}</v-btn>
            <SaveButton
                :loading="form.processing"
                type="button"
                @click="submitCreateTicketContentForm"
            ></SaveButton>
        </template>
    </DialogModal>
</template>

<style scoped></style>
