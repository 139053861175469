<script setup>
import { reactive, ref, watch } from 'vue';
import { watchDebounced } from '@vueuse/core';
import AutocompleteField from '@/Components/AutocompleteField.vue';

const batch = defineModel({
    type: [String, null],
    required: true,
});

const props = defineProps({
    itemNumber: {
        type: [String, null],
        default: null,
    },
});

const filters = reactive({
    number: '',
    item_number: props.itemNumber,
});
const items = ref([]);
const loading = ref(false);

let abortController = null;
watchDebounced(filters, async () => {
    if (filters.number && filters.number !== batch.value) {
        items.value = [];

        if (abortController !== null) {
            abortController.abort();
        }

        loading.value = true;
        try {
            const response = await axios.get(
                route('api.maillog.batch.index', {
                    filter: filters,
                }),
            );
            items.value = response.data;
        } finally {
            loading.value = false;
            abortController = null;
        }
    }
});

watch(
    () => props.itemNumber,
    (value) => {
        filters.item_number = value;
        items.value = [];
        batch.value = null;
    },
);
</script>

<template>
    <AutocompleteField
        v-model="batch"
        v-model:search="filters.number"
        :items
        :loading
        item-title="number"
        item-value="number"
    ></AutocompleteField>
</template>

<style scoped></style>
